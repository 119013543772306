import React , { useState , useEffect } from 'react'
import './mob_address.css'
import plus_1 from '../../../assets/img/plus_1.png'
import { useDispatch , useSelector } from 'react-redux'
import axios from 'axios'
import { ViewCart } from '../../../redux/actions/productAction'
import {
  FormLabel,
  Input,
  Button,
  ChakraProvider
} from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import { Base_Url } from '../../globalvariable/globe'
import { useNavigate } from 'react-router-dom'

export default function Mob_address() {

  let navigate = useNavigate()

  let dispatch = useDispatch() 
  let user = localStorage.getItem('user_data')

  let product =  useSelector((state)=>state)
  let alladdress = product.viewcart.address

  const [ sw , setSw ] = useState(0)
  const [ num, setNum ] = useState(1)

  const [ newAddresses , setnewAddresses ] = useState(false) 

  const [ editupdate , setEditupdate ] = useState(false)

  const [ fname , setFname ] = useState('')
  const [ lname , setLname ] = useState('')
  const [ city , setCity ] = useState('')
  const [ state , setState ] = useState('')
  const [ pincode , setPincode ] = useState('')
  const [ geocode , setGeocode ] = useState('')
  const [ newaddress , setNewaddress ] = useState('')
  const [ subaddress , setSubaddress ] = useState('')

  const [ fnames , setFnames ] = useState(false)
  const [ lnames , setLnames ] = useState(false)
  const [ citys , setCitys ] = useState(false)
  const [ states , setStates ] = useState(false)
  const [ pincodes , setPincodes ] = useState(false)
  const [ geocodes , setGeocodes ] = useState(false)
  const [ newaddresss , setNewaddresss ] = useState(false)
  const [ subaddresss , setSubaddresss ] = useState(false)
  const [ lat , setLat ] = useState('')
  const [ long , setLong ] = useState('')

  const [ editid , setEditId ] = useState('')

  const errnotify = (dat) => toast.error( dat , {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const susnotify = (dat) => toast.success( dat , {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  useEffect(()=>{
    allData()
    setnewAddresses(false)
    setFnames(false)
    setLnames(false)
    setCitys(false)
    setStates(false)
    setPincodes(false)
    setGeocodes(false)
    setNewaddresss(false)
    setSubaddresss(false)
    setEditupdate(false)

    getLocation()
  },[])

  useEffect(()=>{
    if(num<1){
      setNum(1)
    }
  },[num])

  const switch_data = (data,key) =>{
    setSw(key)
  }

  const allData = async()=> {

    let user = localStorage.getItem('user_data')

    if ( user === null ){
      navigate ( '/')
      return
    }
    let responce = await axios({
          method : 'post',
          url : `${Base_Url}/viewCart` ,
          headers: { 
              'Authorization': `Bearer ${user}` 
          }
      })
      .then((res)=>{
          if(res.data.status === true){
            console.log(res.data.Data,'awkwkfhejgeebgbruit')
              dispatch(ViewCart(res.data.Data))
          }else{

          }
      })
      .catch((err) => {

      })     
  }

  const Addsub = async()=>{
    if( fname === ''|| lname === ''|| city === ''|| state === ''|| pincode === ''|| newaddress === ''|| subaddress === ''){

      if(fname === '' ){
        setFnames(true)
      }else{
        setFnames(false)
      }

      if(lname === '' ){
        setLnames(true)
      }else{
        setLnames(false)
      }

      if(city === '' ){
        setCitys(true)
      }else{
        setCitys(false)
      }

      if(state === '' ){
        setStates(true)
      }else{
        setStates(false)
      }

      if(pincode === '' ){
        setPincodes(true)
      }else{
        setPincodes(false)
      }

      // if(geocode === '' ){
      //   setGeocodes(true)
      // }else{
      //   setGeocodes(false)
      // }

      if(newaddress === '' ){
        setNewaddresss(true)
      }else{
        setNewaddresss(false)
      }

      if(subaddress === '' ){
        setSubaddresss(true)
      }else{
        setSubaddresss(false)
      }
      return
    }else{
      setFnames(false)
      setLnames(false)
      setCitys(false)
      setStates(false)
      setPincodes(false)
      setGeocodes(false)
      setNewaddresss(false)
      setSubaddresss(false)
    }

    let responce = await axios({
      method : 'post',
      url : `${Base_Url}/addUserDeliveryAddress` ,
      headers: { 
          'Authorization': `Bearer ${user}` 
      },
      data : {
        firstName : fname ,
        lastName : lname ,
        deliveryAddress : newaddress ,
        appartment : subaddress ,
        city : city ,
        state : state ,
        post : pincode,
        latitude : lat ,
        longitude : long
      }
    })
    .then((res)=>{
      console.log(res.data)
      let sus = 'Address Added Successfully'
      susnotify(sus) 
      setnewAddresses(!newAddresses)
      allData()
    })

  }

  const adnewwww = () =>{
    setEditupdate(false)
    setnewAddresses(!newAddresses)
    setFname('')
    setLname('')
    setCity('')
    setState('')
    setPincode('')
    setNewaddress('')
    setSubaddress('')
  }

  const editAddress = ( data , key ) => {

    console.log(data)
 
    setEditupdate(true)

    setnewAddresses(!newAddresses)
    setFname(data.UserDelivAddrFName)
    setLname(data.UserDelivAddrLName)
    setCity(data.UserDelivAddrCity)
    setState(data.UserDelivAddrState)
    setPincode(data.UserDelivAddrPost)
    setNewaddress(data.UserDelivAddrAddress)
    setSubaddress(data.UserDelivAddrAppart)
    setEditId(data.UserDelivAddrId)

    console.log(data.UserDelivAddrId)
  }

  const Update = async () =>{
    let responce = await axios({
      method : 'post',
      url : `${Base_Url}/updateUserDeliveryAddress` ,
      headers: { 
          'Authorization': `Bearer ${user}` 
      },
      data : {
        firstName : fname ,
        lastName : lname ,
        deliveryAddress : newaddress ,
        appartment : subaddress ,
        city : city ,
        state : state ,
        post : pincode,
        addressId : editid,
        latitude : lat ,
        longitude : long
      }
    })
    .then((res)=>{
      let sus = 'Address Update Successfully'
      susnotify(sus)
      setnewAddresses(!newAddresses)
      allData()
    })
  }

  const deleteAddress = async ( data , key ) =>{
    let responce = await axios({
      method : 'post' ,
      url : `${Base_Url}/deleteUserDeliveryAddress` , 
      headers: { 
        'Authorization': `Bearer ${user}` 
      },
      data :{
        addressId : data.UserDelivAddrId
      }
    })
    .then((res)=>{
      console.log(res)
      let sus = 'Address Delected Successfully'
      susnotify(sus) 
      allData()
      // dispatch(CartList(res.data.Data))
    })
    .catch((err)=>{
      console.log(err)
      let er = 'Something Went Wrong'
      errnotify(er)
    })
}

function getLocation() {
  navigator.geolocation.getCurrentPosition((position) => {
    let lat = position.coords.latitude;
    let long = position.coords.longitude;
    setLat(lat)
    setLong(long)
    console.log(lat,long)
  })
}

  return (
    <>
    {
        newAddresses ? 
          <>
            <p onClick={()=>setnewAddresses(!newAddresses)} className='web_cart_col_four ' style={{marginRight:'24px', color:'#F4B644' , cursor:'pointer' , marginTop:'-5px' , marginBottom:'15px' }} >Add Address</p>
            <ChakraProvider>
              <div className='row'>
                <div className='col-6 '>
                  
                  <FormLabel htmlFor="name">First Name</FormLabel>
                  <Input className={( fnames ? 'inputttt_red' : '')} onChange={(e)=>{
                          let letters = /^[1-9][0-9]*$/;
                          let last = ''
                          if(e.target.value.length >=2) {
                              last = e.target.value.split('').pop()
                          }else{
                              last = e.target.value
                          }
                          if(last.match(letters)){

                          }else if (last.match(0)) {

                          }else{
                              console.log(e)
                              setFname(e.target.value)
                          }
                        }} value={fname} id="name" type="text" />
                  {
                    fnames ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Name</p> : ''
                  }
                </div>
                <div className='col-6'>
                  <FormLabel htmlFor="name">Last Name</FormLabel>
                  <Input className={( lnames ? 'inputttt_red' : '')} onChange={(e)=>{
                          let letters = /^[1-9][0-9]*$/;
                          let last = ''
                          if(e.target.value.length >=2) {
                              last = e.target.value.split('').pop()
                          }else{
                              last = e.target.value
                          }
                          if(last.match(letters)){

                          }else if (last.match(0)) {

                          }else{
                              console.log(e)
                              setLname(e.target.value)
                          }
                        }} value={lname} id="name" type="text" />
                  {
                    lnames ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Name</p> : ''
                  }
                </div>
                <div className='col-6 mt-2'>
                  <FormLabel htmlFor="name">City</FormLabel>
                  <Input className={( citys ? 'inputttt_red' : '')} onChange={(e)=>{
                          let letters = /^[1-9][0-9]*$/;
                          let last = ''
                          if(e.target.value.length >=2) {
                              last = e.target.value.split('').pop()
                          }else{
                              last = e.target.value
                          }
                          if(last.match(letters)){

                          }else if (last.match(0)) {

                          }else{
                              console.log(e)
                              setCity(e.target.value)
                          }
                        }} value={city} id="name" type="text" />
                  {
                    citys ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid CityName</p> : ''
                  }
                </div>
                <div className='col-6 mt-2'>
                  <FormLabel htmlFor="name">State</FormLabel>
                  <Input className={( states ? 'inputttt_red' : '')} onChange={(e)=>{
                          let letters = /^[1-9][0-9]*$/;
                          let last = ''
                          if(e.target.value.length >=2) {
                              last = e.target.value.split('').pop()
                          }else{
                              last = e.target.value
                          }
                          if(last.match(letters)){

                          }else if (last.match(0)) {

                          }else{
                              console.log(e)
                              setState(e.target.value)
                          }
                        }} value={state} id="name" type="text" />
                  {
                    states ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid StateName</p> : ''
                  }
                </div>
                <div className='col-6 mt-2'>
                  <FormLabel htmlFor="name">Postal Code</FormLabel> 
                  <Input className={( pincodes ? 'inputttt_red' : '')} onChange={(e)=>setPincode(e.target.value.replace(/[^0-9]/g, "").slice( 0 , 6 ))} value={pincode} id="name" />
                  {
                    pincodes ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Pincode</p> : ''
                  }
                </div>
                <div className='col-6 mt-2'>
                  {/* <FormLabel htmlFor="name">Location</FormLabel>
                  <Input className={( geocodes ? 'inputttt_red' : '')} value={lat +'  '+ long} id="name" type="text" readonly />
                  {
                    geocodes ? <p style={{color:'red',fontSize:'9px'}}>Enter Your Location</p> : ''
                  } */}
                </div>
              </div>

                <FormLabel className='mt-2' htmlFor="name">Address</FormLabel>
              <Input className={( newaddresss ? 'inputttt_red' : '')} onChange={(e)=>setNewaddress(e.target.value)} value={newaddress} id="name" type="text" />
              {
                newaddresss ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Address</p> : ''
              }

              <FormLabel className='mt-2' htmlFor="name">Appartment, Suite, Villa, Etc...</FormLabel>
              <Input className={( subaddresss ? 'inputttt_red' : '')} onChange={(e)=>setSubaddress(e.target.value)} value={subaddress} id="name" type="text" />
              {
                subaddresss ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Address</p> : ''
              }
              {
                editupdate ? <Button className='mt-2' onClick={()=>Update()} color='white' colorScheme='yellow' >Update</Button>
                :
                <Button className='mt-2' onClick={()=>Addsub()} color='white' colorScheme='yellow' >Submit</Button>
              }
              </ChakraProvider>
            </>
            :
            <>
              <div className='d-flex justify-content-between'>
                {/* <p className='address_one mb-2'>Address</p> */}
              </div>
                <div onClick={()=>adnewwww()} className='address_two'>
                    <div className='d-flex justify-content-center'>
                        <img className='address_four' src={plus_1} />
                        <p className='address_three'>Add a new address</p>
                    </div>
                </div>
                <div className='row pb-4'>
                { alladdress?.map((data,key)=>{
                    return(
                    <div onClick={()=>switch_data(data,key)}  className='col-12'>
                      <div className={(sw===key? 'web_cart_col_monesss mt-3' : 'web_cart_col_mfivesss mt-3')}  >
                        <div className='row'>
                          <div className='col-10'>
                            <p className='web_cart_col_mtwo'>{data.UserDelivAddrFName}</p>
                            <p className='web_cart_col_mthree'>{data.UserDelivAddrAddress}</p>
                          </div>
                          <div className='col-2'>
                            {/* <input style={{marginTop : '29px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} /> */}
                          </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                          <div 
                          onClick={()=>editAddress(data,key)} 
                          className='edit_address'>
                            <p className='edit_address_p'>Edit</p>
                          </div>
                          <div onClick={()=>deleteAddress(data,key)} className='delete_address'>
                            <p className='delete_address_p'>Delete</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                  })
                  }
                </div>
            </>
          } 
      <ToastContainer />
      
    </>
  )
}
