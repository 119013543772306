export let Qntyy = 1 ;

export const ImageUrl = 'https://rentla-image-bucket.s3.ap-south-1.amazonaws.com'

// export const Base_Url = 'http://rentlaexcel.akprojects.co/api'

// export const Base_Url = 'http://3.109.212.216/rentlaadmin/api'

// export const Base_Url = 'http://192.168.100.15/web/rentla/public/api'

// export const Base_Url = 'http://192.168.100.110/rentlaadmin/api'

// export const Base_Url = 'http://192.168.100.2/web/rentlaadmin/api'

// export const Base_Url = 'https://rentla.in/rentlanewmodule/api/ve2'  
// export const Base_Url = 'https://rndinfoserve.com/rentla/rentlanewmodule/api/ve2'
export const Base_Url = 'https://rentlanew.akprojects.co/api/ve2'

export const newURL = 'newbuild'

//test
// export const Base_Url = 'https://rentla.akprojects.co/api'
