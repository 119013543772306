import React , { useState } from 'react'
import logo from '../assets/img/logo_9.png'
import { Link } from 'react-router-dom'
import facebook from '../assets/img/facebook.png'
import ins from '../assets/img/ins.png'
import twi from '../assets/img/twi.png'
import youtube from '../assets/img/youtube.png'
import axios from 'axios'
import { Base_Url } from '../components/globalvariable/globe'
import li from '../assets/img/li.png'

export default function Footer(props) {

    const [ email , setEmail ] = useState('')
    const [ vali , setVali ] = useState(false)
    const [ err , setErr ] = useState('Enter Valid Email')
    const year=new Date().getFullYear()
    console.log(year,"yearr")
    let submit = async ()  => {
        setVali(false)
        if(email === ''){
            setVali(true)
            setErr('Enter Email id')
            return
        }
        let res = await axios ({
            method : 'post',
            url : `${Base_Url}/getEmail`,
            data : {
                email : email
            }
        })
        .then((res)=>{
            if(res.data.status === true){
                setEmail('')
                setVali(true)
                setErr('Email Updated')
                setVali(false)
            }else{
                setVali(true)
                setErr('Enter Valid Email Id')
            }
        })
        .catch((err)=>{
            setVali(true)
            setErr('Something Went Wrong')
            console.log(err)
        })
    }

  return (
    <footer style={{ paddingTop :  20 }} className={ props?.no === 1 ? "footer-area bg-gray pb-10" : "footer-area bg-gray  pb-10"}>
        <div className="container web_cat_five">
            <div className="row">
                <div className='col-xl-6 col-xxl-6 col-md-4 col-sm-4 col-lg-4'>
                    <div className="copyright mb-30">
                        <div className="footer-logo">
                            <div style={{width : 200}}>
                            <Link to='/'>
                                <img alt="" src={logo} />
                            </Link>
                            </div>
                        </div>
                        <p style={{fontSize : 12 ,  fontWeight : 500 , marginTop : 30}} >© {year} <a >Rentla</a>.</p>
                        <p style={{fontSize : 12 ,  fontWeight : 500  }} > All Rights Reserved</p>
                        
                    </div>
                </div>
                <div className='col-xl-6 col-xxl-6 col-md-8 col-sm-8 col-lg-8'>
                    <div className='row'>
                        <div className='col-3 mt-4'>
                            <p className='footeresone' style={{fontSize :14 , fontWeight : 500 ,  color : '#000' , marginBottom: 5}} >INFORMATION</p>
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/about_us'>About us</Link></p>
                            {/* <p style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 10 ,  fontWeight : 400 , cursor : 'pointer'}}>Store location</p> */}
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/contact_us'>Contact Us</Link></p>
                            {/* <p style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 10 ,  fontWeight : 400 , cursor : 'pointer'}}>Orders tracking</p> */}
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/refund_return_policy'>Return/Handover</Link></p>
                            
                           

                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/shipping_policy'>Shipping Policy</Link></p>
                            
                        </div>
                        <div className='col-3 mt-4'>
                            <p className='footeresone' style={{fontSize :14 , fontWeight : 500 ,  color : '#000' , marginBottom: 5}} >USEFUL LINKS</p>
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/terms'>T & C</Link></p>
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/privacy_policy'>Privacy Policy</Link></p>
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/damage_policy'>Damage Policy</Link></p>
                            {/* <p style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 10 ,  fontWeight : 400 , cursor : 'pointer'}}>Size Guide</p> */}
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/faq'>FAQ's</Link></p>    
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link to='/blog'>Blog</Link></p>    

                        
                        </div>
                        <div className='col-6 mt-4'>
                            <p className='footeresone' style={{fontSize :14 , fontWeight : 500 ,  color : '#000' , marginBottom: 15}} >FOLLOW US</p>
                            <div className='d-flex justify-content-start'>
                                <a href='https://www.facebook.com/rentla.ind' target="_blank" >
                                    <img src={facebook} style={{width : 16 ,  height : 18 }} />
                                </a>
                                <a href='https://www.instagram.com/rentla.in/' target="_blank" >
                                    <img src={ins} style={{width : 16 ,  height : 18 , marginLeft : 10 }} />
                                </a>
                                <a href='https://www.linkedin.com/company/rentla-in/' target="_blank" >
                                    <img src={li} style={{width : 16 ,  height : 18 , marginLeft : 10 }} />
                                </a>
                                {/* <img src={twi} style={{width : 16 ,  height : 18 , marginLeft : 10 }} />
                                <img src={youtube} style={{width : 16 ,  height : 18 , marginLeft : 10}} /> */}
                            </div>
                            <div>
                                <div style={{ width : '100%' , height : 50 , padding : '8px 10px' , borderRadius : 5 , border : '1px solid #303651' , marginTop : 20 }}>
                                    <div className='row'>
                                        <div className='col-xl-8 col-xxl-8 col-md-6 col-sm-6 col-lg-8'>
                                            <input placeholder='Enter Email Id' onChange={(e)=>setEmail(e.target.value)} value={email} style={{backgroundColor : 'unset' , border : 'unset' , marginTop : '-6px'}} type='text' />
                                        </div>
                                        <div className='col-xl-4 col-xxl-4 col-md-6 col-sm-6 col-lg-4' >
                                            <div onClick={()=>submit()} style={{ padding: 5, cursor : 'pointer', width: '100%' , height : '85%' , backgroundColor : '#303651' , borderRadius : 5}}>
                                                <p className='lllllmp' >Submit</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                vali === true ?  <p style={{color : 'red' , }}>{err}</p>   : ''
                            }
                            {
                                err === 'Email Updated' ? <p style={{color : 'green' , }}>{err}</p> : ''
                            }
                            
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-2 col-md-4 col-sm-4">
                    <div className="footer-widget mb-30 ml-50">
                        <div className="footer-title">
                            <h3>USEFUL LINKS</h3>
                        </div>
                        <div className="footer-list">
                            <ul>
                                <li><a ><Link to='/terms'>T & C</Link></a></li>
                                <li><a ><Link to='/privacy_policy'>Privacy Policy</Link></a></li>
                                <li><a ><Link to='/damage_policy'>Damage Policy</Link></a></li>
                                <li><a ><Link to='/faq'>FAQs</Link></a></li>
                                <li><a ><Link to='/refund_return_policy'>Return/Handover</Link></a></li>
                                <li><a ><Link to='/shipping_policy'>Shipping Policy</Link></a></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-lg-2 col-md-6 col-sm-6">
                    <div className="footer-widget mb-30 ml-75">
                        <div className="footer-title">
                            <h3>FOLLOW US</h3>
                        </div>
                        <div className="footer-list">
                            <ul>
                                <li><a href='https://www.facebook.com/rentla.ind' target="_blank" >Facebook</a></li>
                                <li><a href='https://www.linkedin.com/company/rentla-in/' target="_blank">Linkedin</a></li>
                                <li><a href='https://www.instagram.com/rentla.in/' target="_blank" >Instagram</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="footer-widget mb-30 ml-70">
                        <div className="footer-title">
                            <h3>Contact Us</h3>
                        </div>
                        <div >
                            <p>Phone : 9940428882</p>
                            <p>Email : support@rentla.in</p>
                            
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </footer>
  )
}